<template>
  <div class="box" v-loading.fullscreen.lock="fullscreenLoading">
    <Title title="广告订单列表"/>
    <div class="ct_box">
      <placard_order_nav ref="placard_order_nav" :nav_id="placard.order_form.category_id" type="order_num"
                         @nav_tab="nav_tab"/>
      <div class="search_status_box flex">
        <div class="flex">
          <order_time @get_time="get_time"/>
          <medium_search :value="placard.order_form.guanggao_name" placeholder="请输入广告名称/订单号/订单名称"
                         @searchMedium="searchMedium"/>
        </div>
        <placard_order_status ref="order_status_tab" type="order_status_num" @status_tab="status_tab"
                              :order_status="placard.order_form.order_status" :status_list="status_list"
                              :category_id="placard.order_form.category_id"/>
      </div>
      <div class="table">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" prop="sn" label="订单号" width="150">
          </el-table-column>
          <el-table-column key="1" v-if="placard.order_form.category_id==1" label-class-name="custom-header-class"
                           class-name="custom-column-cell"
                           header-align="center" align="center" label="户外名称" width="200">
            <template slot-scope="scope">
              <td_remark :text="scope.row.gugangao_info_title"/>
            </template>
          </el-table-column>
          <el-table-column key="2" v-if="placard.order_form.category_id==2" label-class-name="custom-header-class"
                           class-name="custom-column-cell"
                           header-align="center" align="center" label="网络名称" width="200">
            <template slot-scope="scope">
              <td_remark :text="scope.row.category_title"/>
            </template>
          </el-table-column>
          <el-table-column key="3" v-if="placard.order_form.category_id==3" label-class-name="custom-header-class"
                           class-name="custom-column-cell"
                           header-align="center" align="center" label="刊物名称" width="200">
            <template slot-scope="scope">
              <td_remark :text="scope.row.category_title"/>
            </template>
          </el-table-column>
          <el-table-column key="4" v-if="placard.order_form.category_id==4" label-class-name="custom-header-class"
                           class-name="custom-column-cell"
                           header-align="center" align="center" label="频道名称" width="200">
            <template slot-scope="scope">
              <td_remark :text="scope.row.gugangao_info_title"/>
            </template>
          </el-table-column>
          <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" label="素材标题" width="200">
            <template slot-scope="scope">
              <td_remark :text="scope.row.title"/>
            </template>
          </el-table-column>
          <el-table-column key="5" v-if="placard.order_form.category_id!=4" label-class-name="custom-header-class"
                           class-name="custom-column-cell"
                           header-align="center" align="center" prop="address" label="广告位置" width="120">
          </el-table-column>
          <el-table-column key="6" v-if="placard.order_form.category_id==4" label-class-name="custom-header-class"
                           class-name="custom-column-cell"
                           header-align="center" align="center" prop="span_time" label="广告时段" width="120">
          </el-table-column>
          <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" prop="portal_title" label="广告类型" width="120">
          </el-table-column>
          <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" prop="order_price" label="金额" width="120">
          </el-table-column>
          <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" prop="cycle" label="投放周期" width="120">
          </el-table-column>
          <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" label="是否设计" width="120">
            <template slot-scope="scope">
              <p
                  :style="{ color: scope.row.design_price !== '0.00' ? 'rgba(57, 204, 12, 1)' : 'rgba(209, 17, 17, 1)' }">
                {{
                  scope.row.design_price !== '0.00' ? '是' : '否'
                }}</p>
            </template>
          </el-table-column>
          <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" label="状态" width="120">
            <template slot-scope="scope">
              <p :style="{ color: status_list[scope.row.order_status + 1].color }">
                {{ status_list[scope.row.order_status + 1].title }}</p>
            </template>
          </el-table-column>
          <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" label="溢价" width="120">
            <template slot-scope="scope">
              <place_premium_button :list="scope.row" @get_list="get_list"/>
            </template>
          </el-table-column>
          <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" prop="ctime" label="创建时间" width="110">
          </el-table-column>
          <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" label="刊登时间" width="110">
            <template slot-scope="scope">
              {{ scope.row.publish_time ? scope.row.publish_time : '暂无' }}
            </template>
          </el-table-column>
          <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" prop="zip" label="退单时间/原因" width="130">
            <template slot-scope="scope">
              {{ scope.row.withdraw_time_text ? scope.row.withdraw_time_text : '暂无' }}
              <td_remark :text="scope.row.reason.reason"/>
            </template>
          </el-table-column>
          <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" fixed="right" label="操作" width="200">
            <template slot-scope="scope">
              <div class="operation" style="justify-content: space-between;">
                <!-- v-if="scope.row.order_status == 2 || (scope.row.order_status == 3 && scope.row.order_url != '') || (scope.row.order_status == 4 && scope.row.order_url != '')" -->
                <span v-if="scope.row.order_status == 0 || scope.row.order_status == 1"
                      @click="lookShow(scope.row.id)">查看素材</span>
                <span @click="view_tab(scope.row)"
                      v-if="scope.row.order_status == 2 || (scope.row.order_status == 3 && scope.row.order_url != '') || (scope.row.order_status == 4 && scope.row.order_url)">查看</span>
                <span
                    v-if="!scope.row.is_timeout && ((scope.row.order_status == 0 && scope.row.is_limit) || (scope.row.order_status == 1 && scope.row.is_limit) || scope.row.order_status == 0)"
                    @click="withdraw(scope.row)">撤稿</span>

                <span
                    v-if="!scope.row.is_timeout && ((scope.row.order_status == 1 && !scope.row.is_limit) || (scope.row.order_status == 2 && !scope.row.is_limit))"
                    @click="status_confirm(scope.row, 3)">申请退款</span>

                <span v-if="scope.row.order_status == 2 && !scope.row.is_timeout"
                      @click="orderShow(scope.row)">提交工单</span>
                <span
                    v-if="(scope.row.order_status == 4 || scope.row.order_status == 9 || scope.row.order_status == 7) && !scope.row.is_timeout"
                    @click="$refs.customer_service.handle()">联系客服</span>
                <span v-if="scope.row.order_status == 3 && !scope.row.is_timeout"
                      @click="cancel(scope.row)">取消退款</span>
                <span v-if="scope.row.order_status == 0 && !scope.row.is_timeout"
                      @click="edit(scope.row)">修改稿件</span>
                <span
                    v-if="scope.row.order_status == 2 || scope.row.order_status == 5 || scope.row.order_status == 8"
                    @click="redispatch_fn(scope.row)">重新发布</span>
              </div>

              <customer_service ref="customer_service"/>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <placard_media_view ref="placard_media_view" :list="view_list"/>
      <!-- 分页器 -->
      <pagination :page="page" @pageChange="pageChange" :total_order_price="total_order_price" :count="count"
                  :total_page="total_page"/>
    </div>

    <el-dialog title="提交工单" :append-to-body="true" :visible.sync="order" :show-close="false">
      <p class="workOrder_title">请描述您的问题</p>
      <el-input type="textarea" :rows="6" resize="none" v-model="work">
      </el-input>
      <div class="flex" style="margin-top:20px; justify-content: flex-end">
        <button class="copy_but" @click="orderUp">确认提交</button>
      </div>
    </el-dialog>

    <placard_rejection_poup :order_type="popupObj.type === false ? popupObj.type : ''" :himt="popupObj.himt"
                            :order_status="order_status" :placeholder="popupObj.placeholder"
                            :reason_title="popupObj.title"
                            :title="popupObj.title" :rejectShow="rejectShow" :rejectItem="rejectItem"
                            @rejectComfirm="rejectComfirm"
                            @rejectClose="rejectShow = false"/>
  </div>
</template>

<script>
import Title from '@/components/UserInfoTitle'
import placard_rejection_poup from '@/components/placard_rejection_poup'
import timeLimit from '@/components/timeLimit'
import {timeCut} from '@/util/timeCut'
import {mapMutations, mapState} from 'vuex'
import placard_order_nav from '@/components/placard_order_nav'
import medium_search from '@/components/medium_search'
import placard_order_status from '@/components/placard_order_status'
import order_link_poup from '@/components/order_link_poup'
import place_premium_button from '@/components/place_premium_button'
import order_time from '@/components/order_time'
import td_remark from '@/components/td_remark'
import placard_media_view from '@/components/placard_media_view'
import customer_service from '@/components/customer_service_poup'

export default {
  components: {
    Title,
    timeLimit,
    placard_rejection_poup,
    placard_order_nav,
    medium_search,
    placard_order_status,
    order_link_poup,
    place_premium_button,
    order_time,
    td_remark,
    placard_media_view,
    customer_service

  },
  computed: {
    ...mapState(['placard']),
    ...mapMutations('placard', ['SET_ORDER_DATA'])
  },
  watch: {
    tableData: {
      handler(newVal) {
        this.$refs.placard_order_nav.getMediaNav()
      }
    }
  },
  mounted() {
    this.get_list()
  },
  data() {
    return {
      // 申请退款状态变量
      requestARefundShow: false,
      // 退款原因
      reasonForRefund: '',
      title: '',
      tableData: [],
      linkShow: false, //查看链接弹窗
      order: false, //提交工单弹窗
      orderItem: {}, //工单信息
      rejectItem: {},
      rejectShow: false,
      serviceShow: false, //客服
      work: '',
      total_order_price: 0,
      total_page: 0, //分页总页数
      count: 0, //总条数
      page: 1,
      medium_name: '',
      linkList: {},
      fullscreenLoading: false, //loding
      order_status: '',
      popupObj: {
        himt: '',
        title: '',
        reason_title: '',
        placeholder: '',

      },
      view_list: {},
      status_list: [
        {
          id: '',
          title: '全部',
        },
        {
          id: 0,
          title: '未处理',
          color: 'rgba(51, 51, 51, 1)'
        },
        {
          id: 1,
          title: '制作中',
          color: 'rgba(235, 173, 59, 1)',
        },
        {
          id: 2,
          title: '已刊登',
          color: 'rgba(57, 204, 12, 1)',
        },
        {
          id: 3,
          title: '申请退款',
          color: 'rgba(235, 173, 59, 1)',
        },
        {
          id: 4,
          title: '拒绝退款',
          color: 'rgba(224, 25, 25, 1)',
        },
        {
          id: 5,
          title: '撤单',
          color: 'rgba(224, 25, 25, 1)',
        },
        {
          id: 6,
          title: '退单',
          color: 'rgba(224, 25, 25, 1)',
        },

      ],

    }
  },
  methods: {
    get_time(time) {
      this.$store.commit('SET_ORDER_DATA', {
        index: 'time',
        value: time ? time : []
      })
      this.pageChange(1)
    },
    view_tab(item) {
      // console.log(item)
      this.view_list = item
      // console.log(this.view_list.order_url_image)
      // console.log(this.view_list.order_url_image.split(','))
      this.view_list.order_url_image_1 = this.view_list.order_url_image.split(',');
      console.log(this.view_list.order_url_image_1);

      this.$refs.placard_media_view.handle()
    },
    status_tab(id) {
      // this.$store.commit('SET_ORDER_DATA', {
      // 	form: 'order_form',
      // 	index: 2,
      // 	value: id
      // })
      this.$store.commit('SET_ORDER_DATA', {
        index: 'order_status',
        value: id
      })
      this.pageChange(1)
    },
    searchMedium(title) {
      this.$store.commit('SET_ORDER_DATA', {
        index: 'guanggao_name',
        value: title
      })
      this.pageChange(1)
    },
    pageChange(pageVal) {
      this.page = pageVal
      this.get_list()
    },
    nav_tab(id) {
      console.log('tab', id)
      this.$store.commit('SET_ORDER_DATA', {
        index: 'category_id',
        value: id
      })
      this.pageChange(1)
    },
    rejectComfirm() {
      this.rejectShow = false
      this.get_list()
    },
    // 提交工单
    orderShow(item) {
      this.order = true
      this.orderItem = item
    },
    // 提交
    orderUp() {
      if (this.work != '') {
        this.curlPost('/api/users/guanggao_work/add', {
          order_id: this.orderItem.id,
          desc: this.work
        }).then(res => {
          console.log(res);
          if (res.data.code) {
            this.get_list()
            this.work = ''
            this.order = false
            return this.$message({
              message: '提交成功',
              type: 'success',
            })

          } else {
            return this.$message({
              message: '提交失败',
              type: 'warning',
            })
          }
        })
      }
    },
    // 申请退款  撤稿要求
    status_confirm(item, status) {
      this.rejectItem = JSON.parse(JSON.stringify(item))
      this.order_status = status
      this.rejectShow = true
      this.popupObj = {
        himt: '',
        title: '',
        placeholder: '',

      }
      if (status == 3) {
        this.popupObj.type = false
        this.popupObj.himt = '提示：此稿件确定要申请退款吗?'
        this.popupObj.title = '申请退款'
        this.popupObj.placeholder = '请输入退款原因'
        this.reason_title = '退款原因'
      } else {
        this.popupObj.himt = '提示：此稿件确定要撤稿吗?'
        this.popupObj.title = '撤稿'
        this.popupObj.placeholder = '请输入撤稿原因'
        this.reason_title = '撤稿原因'
      }
    },

    // 状态为未处理的稿件走不需要提交input的接口
    withdraw(item) {
      this.$confirm('是否撤稿?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.curlPost('/api/users/guanggao_order/edit', {
          id: item.id,
          status: 5,
        }).then(res => {

          if (res.data.code) {
            this.get_list()
            this.$message({
              type: 'success',
              message: '已撤稿'
            });
          }
        })
      })
    },
    // 编辑稿件
    edit(item) {
      this.$router.push({
        path: '/placard_user/placard_fill',
        query: {
          id: item.id
        }
      })
    },
    // 取消退款
    cancel(item) {
      console.log(item);
      this.$confirm('是否取消退款?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.curlPost('/api/users/guanggao_order/edit', {
          id: item.id,
          status: 1,
          url: item.order_url,
        }).then(res => {
          console.log(res);
          if (res.data.code) {
            this.$message({
              type: 'success',
              message: '待发布 '
            });
            this.get_list()
          }
        })
      })
    },
    // 再发布
    redispatch_fn(item) {
      this.placard.date_form.category_id = item.category_id
      this.$store.commit('SETACTIVEMENU', '/placard_user/user_outdoors_placard_media')
      this.$router.push({
        path: '/placard_user/user_outdoors_placard_media',
      })
    },
    lookShow(id) {
      const {href} = this.$router.resolve({
        name: 'placard_lookdetails',
        query: {
          id: id
        }
      })
      window.open(href, '_blank',)
    },
    get_list() {
      this.fullscreenLoading = true
      let data = {}
      data.page = this.page
      data.limit = 20
      data.category_id = this.placard.order_form.category_id
      if (this.placard.order_form.order_status !== '') {
        data.order_status = this.placard.order_form.order_status
        console.log(data.order_status)
      }
      if (this.placard.order_form.guanggao_name !== '') {
        data.guanggao_name = this.placard.order_form.guanggao_name
      }
      this.curlGet('/api/users/guanggao_order/list', data).then(res => {

        // console.log('判断标题', res)
        if (res.data.code) {
          this.total_page = res.data.data.total_page
          this.count = res.data.data.count
          this.tableData = res.data.data.list

          this.total_order_price = data.order_status == 2 ? res.data.data.total_order_price : 0
          this.tableData.forEach((item, index) => {
            if (this.tableData[index].withdraw_time) {
              this.tableData[index].withdraw_time = timeCut(this.tableData[index].withdraw_time * 1000)
            }
            this.tableData[index].createtime = timeCut(this.tableData[index].createtime * 1000)
            if (this.tableData[index].publish_time) {
              this.tableData[index].publish_time = timeCut(this.tableData[index].publish_time * 1000)
            }
          })
        }
      })
      this.fullscreenLoading = false
    },
  },
}

</script>

<style lang="scss">
@import '@/scss/placard_media';
@import '@/scss/order_work';
///* 自定义滚动条整体样式 */
//.el-table__body-wrapper::-webkit-scrollbar {
//  width: 8px; /* 垂直滚动条宽度 */
//  height: 8px; /* 水平滚动条高度 */
//}
//
///* 自定义滚动条轨道样式 */
//.el-table__body-wrapper::-webkit-scrollbar-track {
//  background-color:red; /* 轨道背景颜色 */
//}
//
///* 自定义滚动条滑块样式 */
//.el-table__body-wrapper::-webkit-scrollbar-thumb {
//  background-color: yellow; /* 滑块背景颜色 */
//  border-radius: 4px; /* 滑块圆角 */
//}
//
///* 鼠标悬停在滑块上时的样式 */
//.el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
//  background-color: blue; /* 悬停时滑块背景颜色 */
//}
</style>